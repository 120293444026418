export const NOTIFICATIONS = {
  login: {
    credentialsError: {
      text: 'Помилка логіну чи паролю',
      type: 'reject',
      iconClass: 'i-alert'
    },
    passwordChangeSuccess: {
      text: 'Пароль успішно змінено',
      type: 'success',
      iconClass: 'i-check-circle'
    },
    emailSentSuccess: {
      text: 'Повідомлення відправлено на пошту',
      type: 'success',
      iconClass: 'i-check-circle'
    },
    MFARecoveryError: {
      text: 'Неправильний код відновлення MFA',
      type: 'reject',
      iconClass: 'i-alert'
    },
    MFAPinError: {
      text: 'Неправильний PIN',
      type: 'reject',
      iconClass: 'i-alert'
    },
    MFAEnableError: {
      text: 'Помилка включення MFA',
      type: 'reject',
      iconClass: 'i-alert'
    }
  },
  form: {
    error: {
      text: 'Поля заповнені некоректно',
      type: 'reject',
      iconClass: 'i-alert'
    },
    updateSuccess: {
      text: 'Успішно оновлено',
      type: 'success',
      iconClass: 'i-check-circle'
    }
  },
  serverError: {
    text: 'Помилка серверу',
    type: 'reject',
    iconClass: 'i-alert'
  },
  order: {
    deliveryDateMainError: {
      text: 'Дата доставки основної групи харчів не обрана',
      type: 'reject',
      iconClass: 'i-alert'
    },
    periodError: {
      text: 'Подача заявки можлива лише у випадку, коли до кінця обраного періоду залишилось більше 10 днів',
      type: 'reject',
      iconClass: 'i-alert'
    },
    contactPersonNameError: {
      text: 'Ім’я контактної особи не заповнено',
      type: 'reject',
      iconClass: 'i-alert'
    },
    contactPersonNameLengthError: {
      text: 'Ім’я контактної особи має бути від 2 до 30 символів',
      type: 'reject',
      iconClass: 'i-alert'
    },
    contactPersonPhoneError: {
      text: 'Номер контактної особи не заповнено',
      type: 'reject',
      iconClass: 'i-alert'
    },
    phoneFormatError: {
      text: 'Номер контактної особи має бути у форматі 38ХХХХХХХХХХ',
      type: 'reject',
      iconClass: 'i-alert'
    },
    noItemsError: {
      text: 'Жодна позиція не додана',
      type: 'reject',
      iconClass: 'i-alert'
    },
    offSeasonalError: (text) => ({
      text: `Неможливо підписати заявку з несезонними товарами - ${text}`,
      type: 'reject',
      iconClass: 'i-alert',
      timeout: 8000
    }),
  },
  file: {
    loading: {
      text: 'Завантаження файлу',
      type: 'warn',
      iconClass: 'i-download'
    },
    uploadSuccess: {
      text: 'Дані з файлу успішно завантажені',
      type: 'success',
      iconClass: 'i-check-circle'
    },
    uploadError: {
      text: 'Помилка завантаження файлу',
      type: 'reject',
      iconClass: 'i-alert'
    },
    catalogCodeError: {
      text: 'У файлі присутні невалідні коди каталогу',
      type: 'reject',
      iconClass: 'i-alert',
    },
  },
  pdf: {
    loadError: {
      text: 'Помилка збереження файлу',
      type: 'reject',
      iconClass: 'i-alert'
    },
  },
  agreements: {
    loadError: {
      text: 'Помилка завантаження договорів',
      type: 'reject',
      iconClass: 'i-alert'
    },
    periodsListError: {
      text: 'Помилка завантаження періодів',
      type: 'reject',
      iconClass: 'i-alert'
    },
    periodAddSuccess: {
      text: 'Період успішно додано',
      type: 'success',
      iconClass: 'i-check-circle'
    },
    periodUpdateSuccess: {
      text: 'Період успішно оновлено',
      type: 'success',
      iconClass: 'i-check-circle'
    },
    periodDeleteSuccess: {
      text: 'Період успішно видалено',
      type: 'success',
      iconClass: 'i-check-circle'
    },
  },
  supplies: {
    missedAttributes: {
      text: 'Спочатку заповніть всі атрибути договору',
      type: 'reject',
      iconClass: 'i-alert'
    },
    deleteSuccess: {
      text: 'Замовлення успішно видалено',
      type: 'success',
      iconClass: 'i-check-circle'
    }
  },
  organizations: {
    getProvisionalUnitsError: {
      text: 'Помилка завантаження підрозділів на забезпеченні',
      type: 'reject',
      iconClass: 'i-alert'
    },
    provisionalOrganizationCreateSuccess: {
      text: 'Базову ВЧ успішно додано',
      type: 'success',
      iconClass: 'i-check-circle'
    },
    provisionalOrganizationUpdateSuccess: {
      text: 'Базову ВЧ успішно оновлено',
      type: 'success',
      iconClass: 'i-check-circle'
    },
    provisionalOrganizationDeleteSuccess: {
      text: 'Базову ВЧ успішно видалено',
      type: 'success',
      iconClass: 'i-check-circle'
    },
  },
  invoices: {
    getInvoicesError: {
      text: 'Помилка завантаження видаткових накладних',
      type: 'reject',
      iconClass: 'i-alert'
    },
    alreadySignedReceiver: {
      text: 'ВН вже підписана отримувачем',
      type: 'reject',
      iconClass: 'i-alert',
    },
    alreadySignedSupplier: {
      text: 'ВН вже підписана постачальником',
      type: 'reject',
      iconClass: 'i-alert',
    },
    departureTerritorialUnitError: {
      text: 'Поле "Пункт Навантаження" обов\'язкове до заповнення',
      type: 'reject',
      iconClass: 'i-alert',
    },
    noItemsError: {
      text: 'Жодна позиція не додана',
      type: 'reject',
      iconClass: 'i-alert'
    },
  },
  peopleCount: 'Не заповнене поле "Добові видачі"',
  peopleCountMax: 'Значення поля Добові видачі не може бути більше 999999',
  dayLimit: 'Перевищено добовий ліміт',
  commonServerError: (text) => ({
    text: text,
    type: 'reject',
    iconClass: 'i-alert',
  }),
};

export const ROLE_ADMIN_LOGIST = 2;
export const ROLE_FOOD_LOGIST = 7;

export const ORDER_STATUS = {
  draft: 1,
  signed: 2,
  deleted: 3,
  distributed: 4,
  partlyDistributed: 5,
};
export const ORDER_TYPE = {
  advancedHumans: 1,
  currentHumans: 2,
  advancedAnimals: 3,
  currentAnimals: 4,
  onlyWater: 5,
};

export const SUPPLY_STATUS = {
  active: 1,
  deleted: 2,
};

export const INVOICE_STATUS = {
  draft: 1,
  signedSupplier: 2,
  signedReceiver: 3,
  signedAll: 4,
  canceled: 5,
};

export const RECEIPT_STATUSES = {
  draft: 1,
  signedBySupplier: 2,
  signedByOrganization: 3,
  confirmed: 4,
  archived: 5,
};
export const ENTITY_STATUSES = {
  order: [
    {status_id: 1, status: 'draft', title: 'Чернетка'},
    {status_id: 2, status: 'signed', title: 'Підписана'},
    {status_id: 3, status: 'deleted', title: 'Видалена'},
    {status_id: 4, status: 'distributed', title: 'Розподілена'},
    {status_id: 5, status: 'partlyDistributed', title: 'Частково розподілена'}
  ],
  supply: [
    {status_id: 1, status: 'active', title: 'Активне'},
    {status_id: 2, status: 'deleted', title: 'Видалене'}
  ],
  amendment: [
    {status_id: 1, status: 'draft', title: 'Чернетка'},
    {status_id: 2, status: 'signed_by_organization', title: 'Очікує погодження від Постачальника'},
    {status_id: 3, status: 'signed_by_supplier', title: 'Очікує погодження від ВЧ'},
    {status_id: 4, status: 'confirmed', title: 'Погоджений'},
    {status_id: 5, status: 'archived', title: 'Видалений'}
  ],
  receipt: [
    {status_id: 1, status: 'draft', title: 'Чернетка'},
    {status_id: 2, status: 'signed_by_supplier', title: 'Підписаний постачальником'},
    {status_id: 3, status: 'signed_by_organization', title: 'Підписаний отримувачем'},
    {status_id: 4, status: 'confirmed', title: 'Підписаний всіма'},
    {status_id: 5, status: 'archived', title: 'Видалений'}
  ],
  qualityComplaints: [
    {status_id: 5, status: 'draft', title: 'Чернетка'},
    {status_id: 10, status: 'sighting', title: 'Візування'},
    {status_id: 15, status: 'signed', title: 'Підписаний'},
    {status_id: 20, status: 'confirmed', title: 'Виставлення претензії'},
    {status_id: 25, status: 'archived', title: 'Видалений'},
    {status_id: 30, status: 'declined', title: 'Відсутні підстави для претензії'}
  ]
};

export const WATER_NOMENCLATURE = {
  waterCategoryIds: [4000, 14000],
  waterCatalogCodes: ['4012', '14012']
};

//only ASCII symbols
export const CITIES_WITHOUT_REGION = 'K';
export const REGION_TYPE = ['O', 'K'];

export const MIN_DAYS_FOR_DELIVERY = 10;

export const ORDER_TYPES_CODES = {
  inexactOrder: [1, 3],
  clarifyingOrder: [2, 4, 5],
};

export const NOMENCLATURE_UNITS = {
  kilo: 'кг',
  piece: 'шт'
};

export const NEW_NOMENCLATURE_DATE = '2024-07-01';

export const CREATE_DELETE_GRANTS = ['create', 'update', 'delete'];

export const DELIVERY_DATE_PARAMS = ['delivery_date_main', 'delivery_date_milk', 'delivery_date_fresh'];

export const DEFAULT_ORDER_TYPE_ID = 2;