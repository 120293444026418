import axios from 'axios';
import store from '@/store';

/**
 * Pass all urls by categories here
 */
const urls = {
  auth: {
    login: 'login/',
    logout: 'logout/',
    csrf: 'sanctum/csrf-cookie/',
    forgotPassword: 'forgot-password/',
    resetPassword: 'reset-password/',
    twoFactorAuthentication: 'two-factor-authentication/',
    twoFactorChallenge: 'two-factor-challenge/',
  },
  appVersion: {
    backend: 'api/version',
  },
  organizations: {
    list: 'api/organizations/',
    get search() {
      return `${this.list}live-search/`;
    },
    get detail() {
      return `${this.list}:organization_id/`;
    },
    get grants() {
      return `${this.detail}grants`;
    },
  },
  agreements: {
    list: 'api/agreements/',
    get detail() {
      return `${this.list}:agreement_id`;
    },
    get agreementItems() {
      return `${this.detail}/agreement-items`;
    },
    get agreementItem() {
      return `${this.agreementItems}/:item_id`;
    },
    get agreementPeriods() {
      return `${this.detail}/agreement-periods`;
    },
    get agreementPeriod() {
      return `${this.agreementPeriods}/:period_id`;
    },
    get search() {
      return `${this.list}live-search/`;
    },
    get grants() {
      return `${this.detail}/grants`;
    },
    get agreementOrders() {
      return `${this.detail}/orders`;
    },
    get agreementPricePeriods() {
      return `${this.detail}/agreement-price-periods`;
    },
    get agreementPricePeriod() {
      return `${this.agreementPricePeriods}/:price_period_id`;
    },
  },
  organizationUnits: {
    list: 'api/organization-units/',
    get search() {
      return `${this.list}live-search/`;
    },
    get detail() {
      return `${this.list}:organization_unit_id/`;
    },
    get grants() {
      return `${this.detail}grants`;
    },
  },
  users: {
    list: 'api/users/',
    get detail() {
      return `${this.list}:user_id/`;
    },
    roles: 'api/users/grants',
    get search() {
      return `${this.list}live-search/`;
    },
    get grants() {
      return `${this.detail}grants`;
    },
    me: 'api/users/me',
  },
  territorialUnits: {
    list: 'api/territorial-units/',
    get search() {
      return `${this.list}live-search`;
    }
  },
  orders: {
    list: 'api/orders',
    get detail() {
      return `${this.list}/:order_id/`;
    },
    get responsibleUser() {
      return `${this.detail}users/:user_id`;
    },
    get periods() {
      return `${this.list}/periods`;
    },
    get grants() {
      return `${this.detail}grants`;
    },
    get search() {
      return `${this.list}/live-search`;
    },
    get file() {
      return `${this.detail}order-items/import`;
    },
    get tags() {
      return `${this.detail}tags`;
    },
    get tagAction() {
      return `${this.detail}tags/:tag_id`;
    }
  },
  nomenclature: {
    json: 'api/nomenclature'
  },
  userRoles: {
    list: 'api/roles'
  },
  staticData: {
    list: 'api/static-data'
  },
  tags: {
    list: 'api/tags',
    get search() {
      return `${this.list}/live-search/`;
    }
  },
  suppliers: {
    list: 'api/suppliers',
    get detail() {
      return `${this.list}/:supplier_id`;
    },
    get agreements() {
      return `${this.detail}/agreements`;
    },
    get search() {
      return `${this.list}/live-search`;
    },
    get grants() {
      return `${this.detail}/grants`;
    },
  },
  supplies: {
    list: 'api/supplies',
    get detail() {
      return `${this.list}/:supply_id`;
    },
    get supplyItems() {
      return `${this.detail}/supply-items`;
    },
    get createAmendment() {
      return `${this.detail}/supply-amendments`;
    },
    get grants() {
      return `${this.detail}/grants`;
    },
    get invoiceGrants() {
      return `${this.detail}/invoices/grants`;
    },
    get supplyAmendmentsGrants() {
      return `${this.detail}/supply-amendments/grants`;
    },
    get search() {
      return `${this.list}/live-search`; // will be change
    },
  },
  logisticSubscriptions: {
    list: 'api/logistic-subscriptions',
    get detail() {
      return `${this.list}/:subscription_id`;
    },
  },
  bulkGrants: {
    list: 'api/bulk-grants'
  },
  supplyAmendments: {
    list: 'api/supply-amendments',
    get detail() {
      return `${this.list}/:amendment_id`;
    },
    get signatureData() {
      return `${this.detail}/signature-data`;
    },
    get signed() {
      return `${this.detail}/signed`;
    },
    get amendmentItems() {
      return `${this.detail}/items`;
    },
    get grants() {
      return `${this.detail}/grants`;
    },
  },
  token: {
    list: '/api/tokens'
  },
  invoices: {
    list: 'api/invoices',
    get detail() {
      return `${this.list}/:invoice_id`;
    },
    get create() {
      return '/api/supplies/:supply_id/invoices';
    },
    get invoiceItems() {
      return `${this.detail}/invoice-items`;
    },
    get invoiceItem() {
      return `${this.invoiceItems}/:item_id`;
    },
    get invoiceSignData() {
      return `${this.detail}/signature-data`;
    },
    get invoiceSigned() {
      return `${this.detail}/signed`;
    },
    get grants() {
      return `${this.detail}/grants`;
    },
    get search() {
      return `${this.list}/live-search`; // will be change
    },
    get createQualityComplaint() {
      return `${this.detail}/quality-complaints`;
    },
    get createQualityComplaintGrants() {
      return `${this.detail}/quality-complaints/grants`;
    },
  },
  receipts: {
    list: 'api/receipts',
    get detail() {
      return `${this.list}/:receipt_id`;
    },
    get signatureData() {
      return `${this.detail}/signature-data`;
    },
    get signed() {
      return `${this.detail}/signed`;
    },
    get grants() {
      return `${this.detail}/grants`;
    },
    get search() {
      return `${this.list}/live-search`;
    },
  },
  qualityComplaints: {
    list: 'api/quality-complaints',
    get detail() {
      return `${this.list}/:complaint_id`;
    },
    get getQualityComplaintItems() {
      return `${this.detail}/quality-complaint-items`;
    },
    get grants() {
      return `${this.detail}/grants`;
    },
    get signatureData() {
      return `${this.detail}/signature-data`;
    },
    get signed() {
      return `${this.detail}/signed`;
    },
  },
  qualityComplaintItem: {
    get detail() {
      return 'api/quality-complaint-items/:id';
    },
  }
};

const config = {
  withCredentials: true,
  withXSRFToken: true,
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    'Accept': 'application/json;',
    'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,OPTIONS',
    'Access-Control-Allow-Origin': '*',
  }
};

const _axios = axios.create(config);

_axios.interceptors.request.use(function (config) {
  const controller = new AbortController();

  config.signal = controller.signal;

  const cutModuleFromUrl = config.url.split('/')[1].split('?')[0];

  if (!location.pathname.includes(cutModuleFromUrl)) {
    store.commit('addAbortController', controller);
  }

  return config;
}, function (error) {
  return Promise.reject(error);
});

export {_axios, urls, config};
