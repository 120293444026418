import {createStore} from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import {VIEW_GRANTS, APP_SECTION} from '@/utils/roles';
import router from '@/router';
import api from '@/api';

export default createStore({
  state: {
    user: {},
    notification: {text: '', type: '', iconClass: ''},
    ready: false, // for widget
    roles: [],
    permissions: [],
    activeSection: '',
    isMobile: false,
    token: '',
    isMenuCollapsed: false,
    subMenuState: {supplies: false},
    userGrants: [],
    abortControllers: [],
  },
  plugins: [
    createPersistedState({
      key: 'dot-data',
      paths: ['user', 'ready', 'roles', 'permissions', 'activeSection', 'isMenuCollapsed', 'subMenuState']
    })
  ],
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setRoles(state, payload) {
      state.roles = [];
      state.roles = payload;
    },
    setPermissions(state, payload) {
      state.permissions = [];
      state.permissions = payload;
    },
    toggleMenu(state) {
      state.isMenuCollapsed = !state.isMenuCollapsed;
    },
    setMenuState(state, payload) {
      state.isMenuCollapsed = payload;
    },
    toggleSubMenu(state, item) {
      state.subMenuState[item] = !state.subMenuState[item];
    },
    setSubMenuState(state, {item, value}) {
      state.subMenuState[item] = value;
    },
    /**
    @param {object} state
    @param {object} payload
    */
    setNotification(state, payload) {
      // if requests were canceled
      if (!payload) {
        return;
      }

      const {text, type, iconClass, timeout = 4000} = payload;

      state.notification.text = text;
      state.notification.type = type;
      state.notification.iconClass = iconClass;
      setTimeout(() => {
        state.notification.text = '';
        state.notification.type = '';
        state.notification.iconClass = '';
      }, timeout);
    },
    setReady(state, ready) {
      state.ready = ready;
    },
    setActiveSection(state, section) {
      state.activeSection = section;
    },
    setMobile(state, payload) {
      state.isMobile = payload;
    },
    setToken(state, token) {
      state.token = token;
    },
    setUserGrants(state, payload) {
      state.userGrants = payload;
    },
    addAbortController(state, controller) {
      state.abortControllers.push(controller);
    },
    clearAbortControllers(state) {
      state.abortControllers = [];
    }
  },
  getters: {
    isBackOffice: (state) => state.activeSection === 'backoffice',
    isCMS: (state) => state.activeSection === 'cms',
    hasPermission: (state) => (module, grants) => {
      if (!Array.isArray(grants)) {
        return state.permissions[module] && state.permissions[module].grants.includes(grants);
      }
      return state.permissions[module] && grants.some((grant) => state.permissions[module].grants.includes(grant));
    },
    isMenuCollapsed: (state) => state.isMenuCollapsed,
    currentUserRoles: (state) => {
      return state.roles;
    },
    getSubMenuState: (state) => (item) => {
      return state.subMenuState[item];
    },
    getFirstAccessibleModule: (state, getters) => (current = 'cms') => {
      return APP_SECTION[current].find((module) => getters.hasPermission(module, VIEW_GRANTS));
    },
    abortControllers: (state) => {
      return state.abortControllers;
    }
  },
  actions: {
    setActiveSectionAndRedirect({commit, getters}, mode) {
      if (!mode) {
        mode = APP_SECTION.cms.some((module) => getters.hasPermission(module, VIEW_GRANTS)) ? 'cms' : 'backoffice';
      }
      commit('setActiveSection', mode);
      const firstAccessibleModule = getters.getFirstAccessibleModule(mode);
      if (firstAccessibleModule) {
        router.push({name: firstAccessibleModule});
      } else {
        router.push({name: 'home'});
      }
    },
    async getUserGrants({commit}, payload) {
      if (!payload) {
        return;
      }

      const {id, method} = payload;
      const [response] = await api[method].grants(id);

      if (response) {
        commit('setUserGrants', response.data.grants);
      }
    },

    cancelPendingRequests({state, commit}) {
      state.abortControllers.forEach((controller) => {
        controller.abort();
      });

      commit('clearAbortControllers');
    }
  }
});
