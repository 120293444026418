import {createRouter, createWebHistory} from 'vue-router';
import api from '@/api';
import store from '@/store';
import {TOP_LEVEL_MODULE_LIST} from '@/utils/roles';
import {fetchVersion} from '@/utils/versionHelper';

const routes = [
  {
    path: '/ui-kit',
    name: 'ui-kit',
    component: () => import('@/views/UiKit'),
    meta: {title: 'DOT - Ui Kit'}
  },

  // Home route
  {
    path: '/',
    name: 'home',
  },

  // Login routes
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView'),
    meta: {title: 'DOT - Логін', isLogin: true}
  },
  {
    path: '/reset-password/:token?',
    name: 'password-reset',
    component: () => import('@/views/PasswordResetView'),
    meta: {title: 'DOT - Відновлення паролю', isLogin: true}
  },

  // Profile routes
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/Profile/index.vue'),
    meta: {title: 'DOT - Профіль користувача'}
  },
  {
    path: '/profile/token/create',
    name: 'token.create',
    component: () => import('@/views/Profile/Token/Create/index.vue'),
    meta: {title: 'DOT - Створення токену'}
  },

  // Order routes
  {
    path: '/orders',
    name: 'orders',
    component: () => import('@/views/Orders'),
    meta: {title: 'Управління потребами тилового забезпечення ЗСУ - Заявки | DOT-Chain', isOrders: true},
  },
  {
    path: '/orders/create/:orderTypeId',
    name: 'orders.create',
    component: () => import('@/views/Orders/Create'),
    meta: {title: 'Управління потребами тилового забезпечення ЗСУ - Створення Заявки | DOT-Chain', isOrders: true}
  },
  {
    path: '/orders/:id/update',
    name: 'orders.update',
    component: () => import('@/views/Orders/Update'),
    meta: {title: 'Управління потребами тилового забезпечення ЗСУ - Редагування Заявки | DOT-Chain', isOrders: true}
  },

  // Organization routes
  {
    path: '/organizations',
    name: 'organizations',
    component: () => import('@/views/Organizations'),
    meta: {title: 'Бекофіс - Реєстр організацій | DOT-Chain', isOrganizations: true}
  },
  {
    path: '/organizations/create',
    name: 'organizations.create',
    component: () => import('@/views/Organizations/Create'),
    meta: {title: 'Бекофіс - Створення організації | DOT-Chain', isOrganizations: true}
  },
  {
    path: '/organizations/:organization_id/update',
    name: 'organizations.update',
    component: () => import('@/views/Organizations/Update'),
    meta: {title: 'Бекофіс - Оновлення організації | DOT-Chain', isOrganizations: true}
  },

  // Agreement routes
  {
    path: '/agreements',
    name: 'agreements',
    component: () => import('@/views/Agreements'),
    meta: {title: 'DOT - Реєстр договорів', isAgreements: true}
  },
  {
    path: '/agreements/:agreement_id/update',
    name: 'agreements.update',
    component: () => import('@/views/Agreements/Update'),
    meta: {title: 'DOT - Оновлення договору', isAgreements: true}
  },

  // OrganizationUnit routes
  {
    path: '/organization-units',
    name: 'organization-units',
    component: () => import('@/views/OrganizationUnits'),
    meta: {title: 'Бекофіс - Реєстр підрозділів | DOT-Chain', isOrganizationUnits: true}
  },
  {
    path: '/organization-units/create',
    name: 'organization-units.create',
    component: () => import('@/views/OrganizationUnits/Create'),
    meta: {title: 'Бекофіс - Створення підрозділу | DOT-Chain', isOrganizationUnits: true}
  },
  {
    path: '/organization-units/:organization_unit_id/update',
    name: 'organization-units.update',
    component: () => import('@/views/OrganizationUnits/Update'),
    meta: {title: 'Бекофіс - Оновлення підроздиілу | DOT-Chain', isOrganizationUnits: true}
  },

  // User routes
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/Users'),
    meta: {title: 'Бекофіс - Реєстр користувачів | DOT-Chain', isUsers: true}
  },
  {
    path: '/users/create',
    name: 'users.create',
    component: () => import('@/views/Users/Create'),
    meta: {title: 'Бекофіс - Створення користувача | DOT-Chain', isUsers: true}
  },
  {
    path: '/users/:user_id/update',
    name: 'users.update',
    component: () => import('@/views/Users/Update'),
    meta: {title: 'Бекофіс - Оновлення користувача | DOT-Chain', isUsers: true}
  },

  // Suppliers routes
  {
    path: '/suppliers',
    name: 'suppliers',
    component: () => import('@/views/Suppliers'),
    meta: {title: 'DOT - Реєстр контрагентів', isSuppliers: true}
  },
  {
    path: '/suppliers/create',
    name: 'suppliers.create',
    component: () => import('@/views/Suppliers/Create'),
    meta: {title: 'DOT - Створення контрагента', isSuppliers: true}
  },
  {
    path: '/suppliers/:supplier_id/update',
    name: 'suppliers.update',
    component: () => import('@/views/Suppliers/Update'),
    meta: {title: 'DOT - Оновлення контрагента', isSuppliers: true}
  },

  // Supplies routes
  {
    path: '/supplies',
    name: 'supplies',
    component: () => import('@/views/Supplies'),
    meta: {title: 'DOT - Реєстр Замовлень', isSupplies: true}
  },
  {
    path: '/supplies/create',
    name: 'supplies.create',
    component: () => import('@/views/Supplies/Create'),
    meta: {title: 'DOT - Створення замовлень', isSupplies: true}
  },
  {
    path: '/supplies/:id/update',
    name: 'supplies.update',
    component: () => import('@/views/Supplies/Update'),
    meta: {title: 'DOT - Оновлення замовлення', isSupplies: true}
  },

  // Invoices routes
  {
    path: '/invoices',
    name: 'invoices',
    component: () => import('@/views/Invoices'),
    meta: {title: 'DOT - Реєстр ВН', isInvoices: true}
  },
  {
    path: '/invoices/:invoice_id/edit',
    name: 'invoices.update',
    component: () => import('@/views/Invoices/Update'),
    meta: {title: 'DOT - Оновлення ВН', isInvoices: true}
  },

  // Supply-amendments routes
  {
    path: '/supply-amendments',
    name: 'supply-amendments',
    component: () => import('@/views/SupplyAmendments'),
    meta: {title: 'DOT - Реєстр запитів на коригування', isSuppliesAmendments: true}
  },
  {
    path: '/supplies/:id/amendments/:amendment_id/edit',
    name: 'supply-amendments.update',
    component: () => import('@/views/SupplyAmendments/Update'),
    meta: {title: 'DOT - Зміна запиту на коригування', isSuppliesAmendments: true}
  },

  //Receipts Routes
  {
    path: '/receipts',
    name: 'receipts',
    component: () => import('@/views/Receipts'),
    meta: {title: 'DOT - Реєстр актів', isReceipts: true}
  },
  {
    path: '/receipts/create',
    name: 'receipts.create',
    component: () => import('@/views/Receipts/Create'),
    meta: {title: 'DOT - Створення акту', isReceipts: true}
  },
  {
    path: '/receipts/:receipt_id/update',
    name: 'receipts.update',
    component: () => import('@/views/Receipts/Update'),
    meta: {title: 'DOT - Оновлення акту', isReceipts: true}
  },

  // Quality Complaints
  {
    path: '/quality-complaints',
    name: 'quality-complaints',
    component: () => import('@/views/QualityComplaints'),
    meta: {title: 'DOT - Реєстр актів про порушення', isQualityComplaints: true}
  },
  {
    path: '/quality-complaints/create',
    name: 'quality-complaints.create',
    component: () => import('@/views/QualityComplaints/Create'),
    meta: {title: 'DOT - Створення акту про порушення', isQualityComplaints: true}
  },
  {
    path: '/quality-complaints/:id/update',
    name: 'quality-complaints.update',
    component: () => import('@/views/QualityComplaints/Update'),
    meta: {title: 'DOT - Оновлення акту про порушення', isQualityComplaints: true}
  },

  //Error Routes
  {
    path: '/server-error',
    name: 'server-error',
    component: () => import('@/views/Errors/ServerError.vue'),
    meta: {title: 'Помилка серверу'}
  },
  {
    path: '/forbidden',
    name: 'forbidden',
    component: () => import('@/views/Errors/Forbidden.vue'),
    meta: {title: 'Недостатньо прав'}
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('@/views/Errors/NotFound.vue'),
    meta: {title: 'Сторінка не знайдена'}
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  routes
});

router.beforeEach(async (to, from, next) => {
  store.dispatch('cancelPendingRequests');

  if (store.state.user.id && to.meta.isLogin) {
    next({name: 'home'});
    return;
  }
  if (!to.meta.isLogin) {
    const [response] = await api.bulkGrants.list({module: TOP_LEVEL_MODULE_LIST});
    if (response) {
      store.commit('setPermissions', response.data.modules);
      store.commit('setRoles', response.data.roles);
    }
    if (to.name === 'home' && store.state.user.id) {
      await fetchVersion();
      await store.dispatch('setActiveSectionAndRedirect');
      next();
      return;
    }
  }
  next();
});

export default router;
