export const ROLES = [
  'admin',
  'admin_logistician',
  'food_applicant',
  'food_applicant_analyst',
  'analyst',
  'admin_suppliers',
  'food_logistician',
  'food_logistician_master',
  'analyst_food',
  'analyst_organization',
  'food_supplier',
  'invoice_signer',
  'receipt_signer',
  'receipt_signer_dot',
].reduce((acc, current) => {
  const formatedName = current.split('_').map((part) => part.toUpperCase()).join('_');
  acc[formatedName] = current;
  return acc;
}, {});

export const ROLE_ACCESS = {
  users: {
    view: ['admin', 'admin_logistician', 'analyst'],
    create: ['admin'],
    update: ['admin']
  },
  organizations: {
    view: ['admin', 'admin_logistician', 'analyst'],
    create: ['admin_logistician'],
    update: ['admin_logistician']
  },
  organizationUnits: {
    view: ['admin', 'admin_logistician', 'analyst'],
    create: ['admin_logistician'],
    update: ['admin_logistician']
  },
  tags: {
    view: ['admin', 'admin_logistician'],
    create: ['admin_logistician'],
    update: ['admin_logistician']
  },
  orders: {
    view: ['food_applicant', 'admin_logistician', 'food_applicant_analyst', 'analyst', 'food_logistician_master'],
    create: ['food_applicant'],
    update: ['food_applicant'],
    sign: ['food_applicant'],
    archivate: ['food_applicant'],
    toDraft: ['admin_logistician'],
    commentsAdd: ['admin_logistician', 'food_applicant'],
    commentsView: ['analyst', 'admin_logistician', 'food_applicant', 'food_applicant_analyst']
  },
  agreements: {
    view: ['food_applicant', 'admin_logistician', 'food_applicant_analyst', 'analyst'],
    create: ['food_applicant'],
    update: ['food_applicant'],
  },
  suppliers: {
    view: ['admin', 'admin_logistician', 'analyst'],
    create: ['admin_logistician'],
    update: ['admin_logistician']
  },
  supplies: {
    view: [
      ROLES.ANALYST_FOOD,
      ROLES.FOOD_LOGISTICIAN_MASTER,
      ROLES.FOOD_LOGISTICIAN,
      ROLES.FOOD_SUPPLIER,
      ROLES.FOOD_APPLICANT,
      ROLES.FOOD_APPLICANT_ANALYST,
    ],
    create: [ROLES.FOOD_LOGISTICIAN_MASTER],
    update: [ROLES.FOOD_LOGISTICIAN_MASTER],
    delete: [ROLES.FOOD_LOGISTICIAN_MASTER],
  },
};

export const GRANTS = {
  create: 'create',
  delete: 'delete',
  update: 'update',
  view: 'view',
  viewAny: 'view-any',
  fromSignedToDraft: 'update-from-signed-to-draft',
  fromDraftToDeleted: 'update-from-draft-to-archived',
  fromDeletedToDraft: 'update-from-archived-to-draft',
  updateResponsibleUser: 'update-responsible-user',
  downloadPdf: 'download-pdf',
  updateInDraft: 'update-in-draft',
  signBySupplier: 'sign-by-supplier',
  signByOrganization: 'sign-by-organization',
  updateFromSignedByOrganization: 'update-from-signed-by-organization-to-draft',
  updateFromSignedBySupplier: 'update-from-signed-by-supplier-to-draft',
  updateFromConfirmedToDraft: 'update-from-confirmed-to-draft',
  createSupplyWithOwnAgreement: 'create-when-supply-only-my-agreement',
  createWhenSupplyOnlyMyOrganizationUnit: 'create-when-supply-only-my-organization-unit',
  updateOnlyForMyAgreement: 'update-only-for-my-agreement',
  signByDot: 'sign-by-dot',
  updateInSigned: 'update-in-signed',
  updateFromSightingToDraft: 'update-from-sighting-to-draft',
  signInDraft: 'sign-in-draft',
  signInSighting: 'sign-in-sighting',
  createWhenInvoiceOnlyMyOrganizationUnit: 'create-when-invoice-only-my-organization-unit',
};

export const VIEW_GRANTS = [
  'view-any',
  'filter-only-user-agreement',
  'filter-only-user-organization-unit',
  'filter-only-user-logistic-subscription',
  'filter-only-user-organization'
];

export const APP_SECTION = {
  cms: ['orders', 'supplies', 'supply-amendments', 'invoices', 'receipts', 'quality-complaints'],
  backoffice: ['users', 'organizations', 'organization-units', 'suppliers'],
};

export const MODULES_ITEMS = [
  'users',
  'orders',
  'order-items',
  'supplies',
  'supply-items',
  'organizations',
  'organization-units',
  'agreements',
  'agreement-items',
  'suppliers',
  'supply-amendments',
  'invoices',
  'receipts',
  'quality-complaints'
].reduce((acc, current) => {
  const formatedName = current.split('-').map((part) => part.toUpperCase()).join('_');
  acc[formatedName] = current;
  return acc;
}, {});

export const TOP_LEVEL_MODULE_LIST = [
  MODULES_ITEMS.ORDERS,
  MODULES_ITEMS.ORDER_ITEMS,
  MODULES_ITEMS.SUPPLIES,
  MODULES_ITEMS.SUPPLY_ITEMS,
  MODULES_ITEMS.SUPPLY_AMENDMENTS,
  MODULES_ITEMS.INVOICES,
  MODULES_ITEMS.RECEIPTS,
  MODULES_ITEMS.USERS,
  MODULES_ITEMS.ORGANIZATIONS,
  MODULES_ITEMS.ORGANIZATION_UNITS,
  MODULES_ITEMS.SUPPLIERS,
  MODULES_ITEMS.AGREEMENTS,
  MODULES_ITEMS.AGREEMENT_ITEMS,
  MODULES_ITEMS.QUALITY_COMPLAINTS,
];
