import api from '@/api';

let appVerFetched = false;
let cachedAppVerResponse = null;

/**
 * Fetches and logs application version from the backend and frontend
 */
export async function fetchVersion() {
  if (process.env.VUE_APP_ENV === 'production') {
    return;
  }
  if (!appVerFetched) {
    const [appVerResponse] = await api.version.getVersion();
    if (appVerResponse) {
      cachedAppVerResponse = appVerResponse;
      appVerFetched = true;
    }
  }
  // eslint-disable-next-line no-console
  console.log(`${process.env.VUE_APP_ENV}\n\nBackend: ${cachedAppVerResponse.data.app_version}\nFrontend: ${process.env.VUE_APP_VERSION}`);
}